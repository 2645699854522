/* Copyright (C) 2017-2022 Greenbone AG
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

const timezones = [
  {name: 'Africa/Abidjan'},
  {name: 'Africa/Accra'},
  {name: 'Africa/Addis_Ababa'},
  {name: 'Africa/Algiers'},
  {name: 'Africa/Asmara'},
  {name: 'Africa/Bamako'},
  {name: 'Africa/Bangui'},
  {name: 'Africa/Banjul'},
  {name: 'Africa/Bissau'},
  {name: 'Africa/Blantyre'},
  {name: 'Africa/Brazzaville'},
  {name: 'Africa/Bujumbura'},
  {name: 'Africa/Cairo'},
  {name: 'Africa/Casablanca'},
  {name: 'Africa/Ceuta'},
  {name: 'Africa/Conakry'},
  {name: 'Africa/Dakar'},
  {name: 'Africa/Dar_es_Salaam'},
  {name: 'Africa/Djibouti'},
  {name: 'Africa/Douala'},
  {name: 'Africa/El_Aaiun'},
  {name: 'Africa/Freetown'},
  {name: 'Africa/Gaborone'},
  {name: 'Africa/Harare'},
  {name: 'Africa/Johannesburg'},
  {name: 'Africa/Juba'},
  {name: 'Africa/Kampala'},
  {name: 'Africa/Khartoum'},
  {name: 'Africa/Kigali'},
  {name: 'Africa/Kinshasa'},
  {name: 'Africa/Lagos'},
  {name: 'Africa/Libreville'},
  {name: 'Africa/Lome'},
  {name: 'Africa/Luanda'},
  {name: 'Africa/Lubumbashi'},
  {name: 'Africa/Lusaka'},
  {name: 'Africa/Malabo'},
  {name: 'Africa/Maputo'},
  {name: 'Africa/Maseru'},
  {name: 'Africa/Mbabane'},
  {name: 'Africa/Mogadishu'},
  {name: 'Africa/Monrovia'},
  {name: 'Africa/Nairobi'},
  {name: 'Africa/Ndjamena'},
  {name: 'Africa/Niamey'},
  {name: 'Africa/Nouakchott'},
  {name: 'Africa/Ouagadougou'},
  {name: 'Africa/Porto-Novo'},
  {name: 'Africa/Sao_Tome'},
  {name: 'Africa/Tripoli'},
  {name: 'Africa/Tunis'},
  {name: 'Africa/Windhoek'},
  {name: 'America/Adak'},
  {name: 'America/Anchorage'},
  {name: 'America/Anguilla'},
  {name: 'America/Antigua'},
  {name: 'America/Araguaina'},
  {name: 'America/Argentina/Buenos_Aires'},
  {name: 'America/Argentina/Catamarca'},
  {name: 'America/Argentina/Cordoba'},
  {name: 'America/Argentina/Jujuy'},
  {name: 'America/Argentina/La_Rioja'},
  {name: 'America/Argentina/Mendoza'},
  {name: 'America/Argentina/Rio_Gallegos'},
  {name: 'America/Argentina/Salta'},
  {name: 'America/Argentina/San_Juan'},
  {name: 'America/Argentina/San_Luis'},
  {name: 'America/Argentina/Tucuman'},
  {name: 'America/Argentina/Ushuaia'},
  {name: 'America/Aruba'},
  {name: 'America/Asuncion'},
  {name: 'America/Atikokan'},
  {name: 'America/Bahia'},
  {name: 'America/Bahia_Banderas'},
  {name: 'America/Barbados'},
  {name: 'America/Belem'},
  {name: 'America/Belize'},
  {name: 'America/Blanc-Sablon'},
  {name: 'America/Boa_Vista'},
  {name: 'America/Bogota'},
  {name: 'America/Boise'},
  {name: 'America/Cambridge_Bay'},
  {name: 'America/Campo_Grande'},
  {name: 'America/Cancun'},
  {name: 'America/Caracas'},
  {name: 'America/Cayenne'},
  {name: 'America/Cayman'},
  {name: 'America/Chicago'},
  {name: 'America/Chihuahua'},
  {name: 'America/Costa_Rica'},
  {name: 'America/Creston'},
  {name: 'America/Cuiaba'},
  {name: 'America/Curacao'},
  {name: 'America/Danmarkshavn'},
  {name: 'America/Dawson'},
  {name: 'America/Dawson_Creek'},
  {name: 'America/Denver'},
  {name: 'America/Detroit'},
  {name: 'America/Dominica'},
  {name: 'America/Edmonton'},
  {name: 'America/Eirunepe'},
  {name: 'America/El_Salvador'},
  {name: 'America/Fortaleza'},
  {name: 'America/Fort_Nelson'},
  {name: 'America/Glace_Bay'},
  {name: 'America/Godthab'},
  {name: 'America/Goose_Bay'},
  {name: 'America/Grand_Turk'},
  {name: 'America/Grenada'},
  {name: 'America/Guadeloupe'},
  {name: 'America/Guatemala'},
  {name: 'America/Guayaquil'},
  {name: 'America/Guyana'},
  {name: 'America/Halifax'},
  {name: 'America/Havana'},
  {name: 'America/Hermosillo'},
  {name: 'America/Indiana/Indianapolis'},
  {name: 'America/Indiana/Knox'},
  {name: 'America/Indiana/Marengo'},
  {name: 'America/Indiana/Petersburg'},
  {name: 'America/Indiana/Tell_City'},
  {name: 'America/Indiana/Vevay'},
  {name: 'America/Indiana/Vincennes'},
  {name: 'America/Indiana/Winamac'},
  {name: 'America/Inuvik'},
  {name: 'America/Iqaluit'},
  {name: 'America/Jamaica'},
  {name: 'America/Juneau'},
  {name: 'America/Kentucky/Louisville'},
  {name: 'America/Kentucky/Monticello'},
  {name: 'America/Kralendijk'},
  {name: 'America/La_Paz'},
  {name: 'America/Lima'},
  {name: 'America/Los_Angeles'},
  {name: 'America/Lower_Princes'},
  {name: 'America/Maceio'},
  {name: 'America/Managua'},
  {name: 'America/Manaus'},
  {name: 'America/Marigot'},
  {name: 'America/Martinique'},
  {name: 'America/Matamoros'},
  {name: 'America/Mazatlan'},
  {name: 'America/Menominee'},
  {name: 'America/Merida'},
  {name: 'America/Metlakatla'},
  {name: 'America/Mexico_City'},
  {name: 'America/Miquelon'},
  {name: 'America/Moncton'},
  {name: 'America/Monterrey'},
  {name: 'America/Montevideo'},
  {name: 'America/Montserrat'},
  {name: 'America/Nassau'},
  {name: 'America/New_York'},
  {name: 'America/Nipigon'},
  {name: 'America/Nome'},
  {name: 'America/Noronha'},
  {name: 'America/North_Dakota/Beulah'},
  {name: 'America/North_Dakota/Center'},
  {name: 'America/North_Dakota/New_Salem'},
  {name: 'America/Ojinaga'},
  {name: 'America/Panama'},
  {name: 'America/Pangnirtung'},
  {name: 'America/Paramaribo'},
  {name: 'America/Phoenix'},
  {name: 'America/Port-au-Prince'},
  {name: 'America/Port_of_Spain'},
  {name: 'America/Porto_Velho'},
  {name: 'America/Puerto_Rico'},
  {name: 'America/Rainy_River'},
  {name: 'America/Rankin_Inlet'},
  {name: 'America/Recife'},
  {name: 'America/Regina'},
  {name: 'America/Resolute'},
  {name: 'America/Rio_Branco'},
  {name: 'America/Santarem'},
  {name: 'America/Santiago'},
  {name: 'America/Santo_Domingo'},
  {name: 'America/Sao_Paulo'},
  {name: 'America/Scoresbysund'},
  {name: 'America/Sitka'},
  {name: 'America/St_Barthelemy'},
  {name: 'America/St_Johns'},
  {name: 'America/St_Kitts'},
  {name: 'America/St_Lucia'},
  {name: 'America/St_Thomas'},
  {name: 'America/St_Vincent'},
  {name: 'America/Swift_Current'},
  {name: 'America/Tegucigalpa'},
  {name: 'America/Thule'},
  {name: 'America/Thunder_Bay'},
  {name: 'America/Tijuana'},
  {name: 'America/Toronto'},
  {name: 'America/Tortola'},
  {name: 'America/Vancouver'},
  {name: 'America/Whitehorse'},
  {name: 'America/Winnipeg'},
  {name: 'America/Yakutat'},
  {name: 'America/Yellowknife'},
  {name: 'Antarctica/Casey'},
  {name: 'Antarctica/Davis'},
  {name: 'Antarctica/DumontDUrville'},
  {name: 'Antarctica/Macquarie'},
  {name: 'Antarctica/Mawson'},
  {name: 'Antarctica/McMurdo'},
  {name: 'Antarctica/Palmer'},
  {name: 'Antarctica/Rothera'},
  {name: 'Antarctica/Syowa'},
  {name: 'Antarctica/Troll'},
  {name: 'Antarctica/Vostok'},
  {name: 'Arctic/Longyearbyen'},
  {name: 'Asia/Aden'},
  {name: 'Asia/Almaty'},
  {name: 'Asia/Amman'},
  {name: 'Asia/Anadyr'},
  {name: 'Asia/Aqtau'},
  {name: 'Asia/Aqtobe'},
  {name: 'Asia/Ashgabat'},
  {name: 'Asia/Baghdad'},
  {name: 'Asia/Bahrain'},
  {name: 'Asia/Baku'},
  {name: 'Asia/Bangkok'},
  {name: 'Asia/Barnaul'},
  {name: 'Asia/Beirut'},
  {name: 'Asia/Bishkek'},
  {name: 'Asia/Brunei'},
  {name: 'Asia/Chita'},
  {name: 'Asia/Choibalsan'},
  {name: 'Asia/Colombo'},
  {name: 'Asia/Damascus'},
  {name: 'Asia/Dhaka'},
  {name: 'Asia/Dili'},
  {name: 'Asia/Dubai'},
  {name: 'Asia/Dushanbe'},
  {name: 'Asia/Gaza'},
  {name: 'Asia/Hebron'},
  {name: 'Asia/Ho_Chi_Minh'},
  {name: 'Asia/Hong_Kong'},
  {name: 'Asia/Hovd'},
  {name: 'Asia/Irkutsk'},
  {name: 'Asia/Jakarta'},
  {name: 'Asia/Jayapura'},
  {name: 'Asia/Jerusalem'},
  {name: 'Asia/Kabul'},
  {name: 'Asia/Kamchatka'},
  {name: 'Asia/Karachi'},
  {name: 'Asia/Kathmandu'},
  {name: 'Asia/Khandyga'},
  {name: 'Asia/Kolkata'},
  {name: 'Asia/Krasnoyarsk'},
  {name: 'Asia/Kuala_Lumpur'},
  {name: 'Asia/Kuching'},
  {name: 'Asia/Kuwait'},
  {name: 'Asia/Macau'},
  {name: 'Asia/Magadan'},
  {name: 'Asia/Makassar'},
  {name: 'Asia/Manila'},
  {name: 'Asia/Muscat'},
  {name: 'Asia/Nicosia'},
  {name: 'Asia/Novokuznetsk'},
  {name: 'Asia/Novosibirsk'},
  {name: 'Asia/Omsk'},
  {name: 'Asia/Oral'},
  {name: 'Asia/Phnom_Penh'},
  {name: 'Asia/Pontianak'},
  {name: 'Asia/Pyongyang'},
  {name: 'Asia/Qatar'},
  {name: 'Asia/Qyzylorda'},
  {name: 'Asia/Riyadh'},
  {name: 'Asia/Sakhalin'},
  {name: 'Asia/Samarkand'},
  {name: 'Asia/Seoul'},
  {name: 'Asia/Shanghai'},
  {name: 'Asia/Singapore'},
  {name: 'Asia/Srednekolymsk'},
  {name: 'Asia/Taipei'},
  {name: 'Asia/Tashkent'},
  {name: 'Asia/Tbilisi'},
  {name: 'Asia/Tehran'},
  {name: 'Asia/Thimphu'},
  {name: 'Asia/Tokyo'},
  {name: 'Asia/Tomsk'},
  {name: 'Asia/Ulaanbaatar'},
  {name: 'Asia/Urumqi'},
  {name: 'Asia/Ust-Nera'},
  {name: 'Asia/Vientiane'},
  {name: 'Asia/Vladivostok'},
  {name: 'Asia/Yakutsk'},
  {name: 'Asia/Yangon'},
  {name: 'Asia/Yekaterinburg'},
  {name: 'Asia/Yerevan'},
  {name: 'Atlantic/Azores'},
  {name: 'Atlantic/Bermuda'},
  {name: 'Atlantic/Canary'},
  {name: 'Atlantic/Cape_Verde'},
  {name: 'Atlantic/Faroe'},
  {name: 'Atlantic/Madeira'},
  {name: 'Atlantic/Reykjavik'},
  {name: 'Atlantic/South_Georgia'},
  {name: 'Atlantic/Stanley'},
  {name: 'Atlantic/St_Helena'},
  {name: 'Australia/Adelaide'},
  {name: 'Australia/Brisbane'},
  {name: 'Australia/Broken_Hill'},
  {name: 'Australia/Currie'},
  {name: 'Australia/Darwin'},
  {name: 'Australia/Eucla'},
  {name: 'Australia/Hobart'},
  {name: 'Australia/Lindeman'},
  {name: 'Australia/Lord_Howe'},
  {name: 'Australia/Melbourne'},
  {name: 'Australia/Perth'},
  {name: 'Australia/Sydney'},
  {name: 'Europe/Amsterdam'},
  {name: 'Europe/Andorra'},
  {name: 'Europe/Astrakhan'},
  {name: 'Europe/Athens'},
  {name: 'Europe/Belgrade'},
  {name: 'Europe/Berlin'},
  {name: 'Europe/Bratislava'},
  {name: 'Europe/Brussels'},
  {name: 'Europe/Bucharest'},
  {name: 'Europe/Budapest'},
  {name: 'Europe/Busingen'},
  {name: 'Europe/Chisinau'},
  {name: 'Europe/Copenhagen'},
  {name: 'Europe/Dublin'},
  {name: 'Europe/Gibraltar'},
  {name: 'Europe/Guernsey'},
  {name: 'Europe/Helsinki'},
  {name: 'Europe/Isle_of_Man'},
  {name: 'Europe/Istanbul'},
  {name: 'Europe/Jersey'},
  {name: 'Europe/Kaliningrad'},
  {name: 'Europe/Kiev'},
  {name: 'Europe/Kirov'},
  {name: 'Europe/Lisbon'},
  {name: 'Europe/Ljubljana'},
  {name: 'Europe/London'},
  {name: 'Europe/Luxembourg'},
  {name: 'Europe/Madrid'},
  {name: 'Europe/Malta'},
  {name: 'Europe/Mariehamn'},
  {name: 'Europe/Minsk'},
  {name: 'Europe/Monaco'},
  {name: 'Europe/Moscow'},
  {name: 'Europe/Oslo'},
  {name: 'Europe/Paris'},
  {name: 'Europe/Podgorica'},
  {name: 'Europe/Prague'},
  {name: 'Europe/Riga'},
  {name: 'Europe/Rome'},
  {name: 'Europe/Samara'},
  {name: 'Europe/San_Marino'},
  {name: 'Europe/Sarajevo'},
  {name: 'Europe/Simferopol'},
  {name: 'Europe/Skopje'},
  {name: 'Europe/Sofia'},
  {name: 'Europe/Stockholm'},
  {name: 'Europe/Tallinn'},
  {name: 'Europe/Tirane'},
  {name: 'Europe/Ulyanovsk'},
  {name: 'Europe/Uzhgorod'},
  {name: 'Europe/Vaduz'},
  {name: 'Europe/Vatican'},
  {name: 'Europe/Vienna'},
  {name: 'Europe/Vilnius'},
  {name: 'Europe/Volgograd'},
  {name: 'Europe/Warsaw'},
  {name: 'Europe/Zagreb'},
  {name: 'Europe/Zaporozhye'},
  {name: 'Europe/Zurich'},
  {name: 'Indian/Antananarivo'},
  {name: 'Indian/Chagos'},
  {name: 'Indian/Christmas'},
  {name: 'Indian/Cocos'},
  {name: 'Indian/Comoro'},
  {name: 'Indian/Kerguelen'},
  {name: 'Indian/Mahe'},
  {name: 'Indian/Maldives'},
  {name: 'Indian/Mauritius'},
  {name: 'Indian/Mayotte'},
  {name: 'Indian/Reunion'},
  {name: 'Pacific/Apia'},
  {name: 'Pacific/Auckland'},
  {name: 'Pacific/Bougainville'},
  {name: 'Pacific/Chatham'},
  {name: 'Pacific/Chuuk'},
  {name: 'Pacific/Easter'},
  {name: 'Pacific/Efate'},
  {name: 'Pacific/Enderbury'},
  {name: 'Pacific/Fakaofo'},
  {name: 'Pacific/Fiji'},
  {name: 'Pacific/Funafuti'},
  {name: 'Pacific/Galapagos'},
  {name: 'Pacific/Gambier'},
  {name: 'Pacific/Guadalcanal'},
  {name: 'Pacific/Guam'},
  {name: 'Pacific/Honolulu'},
  {name: 'Pacific/Johnston'},
  {name: 'Pacific/Kiritimati'},
  {name: 'Pacific/Kosrae'},
  {name: 'Pacific/Kwajalein'},
  {name: 'Pacific/Majuro'},
  {name: 'Pacific/Marquesas'},
  {name: 'Pacific/Midway'},
  {name: 'Pacific/Nauru'},
  {name: 'Pacific/Niue'},
  {name: 'Pacific/Norfolk'},
  {name: 'Pacific/Noumea'},
  {name: 'Pacific/Pago_Pago'},
  {name: 'Pacific/Palau'},
  {name: 'Pacific/Pitcairn'},
  {name: 'Pacific/Pohnpei'},
  {name: 'Pacific/Port_Moresby'},
  {name: 'Pacific/Rarotonga'},
  {name: 'Pacific/Saipan'},
  {name: 'Pacific/Tahiti'},
  {name: 'Pacific/Tarawa'},
  {name: 'Pacific/Tongatapu'},
  {name: 'Pacific/Wake'},
  {name: 'Pacific/Wallis'},
];

export default timezones;

// vim: set ts=2 sw=2 tw=80:
