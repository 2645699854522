/* Copyright (C) 2017-2022 Greenbone AG
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import Model from 'gmp/model';
import {isDefined} from 'gmp/utils/identity';
import {parseDate} from 'gmp/parser';

class Vulnerability extends Model {
  static entityType = 'vulnerability';

  static parseElement(element) {
    const ret = super.parseElement(element);

    if (isDefined(ret.results)) {
      ret.results.newest = parseDate(ret.results.newest);
      ret.results.oldest = parseDate(ret.results.newest);
    }

    return ret;
  }
}

export default Vulnerability;

// vim: set ts=2 sw=2 tw=80:
